<template>
  <div class="page steps">
    <!-- <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps> -->
    <div class="steps__content">
      <edit-product ref="editProduct"></edit-product>
    </div>
    <div class="steps__action">
     
      <a-button
        type="primary"
        @click="submit"
        style="margin-right: 10px;"
      >
        保存
      </a-button>
       <!-- <a-button @click="next">
        下一步
      </a-button> -->
      <!-- <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
        上一步
      </a-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsCreate",
  components: {
    editProduct: () => import('./editProduct')
  },
  data() {
    return {     
    };
  },
  methods: {
    submit () {
      let info = this.$refs.editProduct;
      console.log(info, 'info')
      let pictures = [];
      info.fileList.forEach(el => {
        pictures.push(el.id)
      })
      let spec_items = [];
      // info.cardDataSource;
      info.dataSource.forEach(el => {
        // let obj = info.cardDataSource.find(c => c.nameShow == el.nameShow);
        // if (obj) {
        //   el.member_price_items = [];
        //   for (let key in obj) {
        //     console.log(key)
        //     if (key.indexOf('card') > -1) {
        //       let b = info.cardChoose.find(c => c.id == key.replace('card', ''));
        //       b && el.member_price_items.push({
        //         card: b.id,
        //         card_name: b.name,
        //         price: obj[key]
        //       })
        //     }
        //   }
        // }
        spec_items.push(el)
      })

      console.log(spec_items, 'spec_items')
      info.$refs.ruleForm.validate(valid => {
        if (valid) {
         let data = {
           ...info.form,
           pictures,
           spec_items: spec_items,
           spec_json: JSON.stringify(info.specArray)

         }
         let request = null;
         if (this.$route.query.id && !this.$route.query.copy) {
           request = this.$axios.put('/goods/'+ this.$route.query.id+'/', data)
         } else {
           request = this.$axios.post('/goods/', data)
         }
         request.then(res => {
           this.$message.success('操作成功')
           console.log(res)
           info.form = {};
           info.fileList = [];
           info.cardChoose = [];
           info.specArray = [];
           info.cardDataSource = [];
           info.dataSource = [];
           this.$router.push({name: 'goodsList'})
         })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.steps {
  position: relative;
  // &__content {
  //   height: calc(100% - 32px - 32px);
  //   padding: 10px 0;
  // }
  &__action {
    text-align: center;
  }
}
</style>